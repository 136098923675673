<template>
  <div class="w-1/3 m-auto mt-102">
    <p class=" mb-16">欢迎【{{ name }}】的用户开通有备平台！</p>
    <p class="text-warning mb-8">请绑定手机号</p>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm.phone"
          maxlength="11"
          class="noborder"
          type="text"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm.smsCaptcha"
          type="text"
          class="noborder"
          maxlength="6"
          placeholder="请输入验证码"
          ><template #append>
            <MsgCode :mobile="ruleForm.phone" :type="'erpBind'" />
          </template>
        </el-input>
      </el-form-item>

      <el-form-item class="w-full" prop="field3">
        <div
          class=" text-white bg-warning rounded text-center cursor-pointer"
          @click="submit"
        >
          确定
        </div>
      </el-form-item>
    </el-form>
    <el-dialog title="绑定账号" :visible.sync="dialogVisible" width="30%">
      <BindAccount
        :accounts="canBindAccounts"
        @choose="choose"
        @creatAccout="creatAccout"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mobileValidate, captchaValidate } from "@/utils/validate";
import MsgCode from "@/components/msgCode/msgCode";
import { STATUS } from "../../../store/index";
import { queryCacheInfo, getAuthInfo, plateFormReg } from "@/api/account";
import BindAccount from "@/components/dialog/BindAccount.vue";
import { profileApi } from "@/api/login";
import qs from "qs";

export default {
  components: {
    MsgCode,
    BindAccount,
  },
  data() {
    return {
      registerCacheId: "",
      name: "",
      STATUS,
      ruleForm: {
        phone: "",
        smsCaptcha: "",
      },
      rules: {
        phone: [{ validator: mobileValidate, trigger: "blur" }],
        smsCaptcha: [{ validator: captchaValidate, trigger: "blur" }],
      },
      canBindAccounts: [],
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(["mutilCompaies", "loginParams"]),
  },
  mounted() {
    const params = qs.parse(location.search.substring(1));
    this.registerCacheId = params["register-cache-id"];
    this.queryCacheInfo();
  },
  methods: {
    // bindCompany(id) {
    //   this.$store.dispatch("login", {
    //     ...this.loginParams,
    //     type: 1, // 1 is for pc
    //     companyId: id,
    //   });
    // },
    queryCacheInfo() {
      queryCacheInfo({
        registerCacheId: this.registerCacheId,
      }).then((res) => {
        const { threeCompanyId, threeUserId } = res;
        const { name } = threeCompanyId;
        const { phone } = threeUserId;
        this.name = name;
        this.ruleForm.phone = phone;
      });
    },
    submit() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          getAuthInfo({
            phone: this.ruleForm.phone,
            smsCaptcha: this.ruleForm.smsCaptcha,
            registerCacheId: this.registerCacheId,
          }).then((res) => {
            if (!res) {
              this.plateFormReg({
                registerCacheId: this.registerCacheId,
                type: 1,
              });
              return;
            }
            const { Rows } = res;
            // "companyName": "",	 # 公司名称
            // "companyId": 0,	 # 公司id
            // "userId": 0,	 # 用户id
            // "userName": "",	 # 用户名
            // "userAccount": "",	 # 用户账号
            // "hasBind": false	 # 是否已经绑定
            // 查询缓存信息GET /s/account/three-in/in/three-register-cache/detail 获取手机号和公司信息
            // 获取授权信息POST /s/account/three-in/in/user/auth 点击确定按钮
            // - 列表为空直接注册 三方注册用户POST /s/account/three-in/in/user/register isBind = false
            // - 选中唯一一个可绑定的直接 三方注册用户POST /s/account/three-in/in/user/register isBind = true
            // - 点击创建公司 三方注册用户POST /s/account/three-in/in/user/register 走创建公司流程
            // - registor 返回有公司信息就跳认证公司页面且填充公司信息,没有公司信息跳首页
            if (!Rows) return;
            if (Rows.length == 0) {
              this.plateFormReg({
                registerCacheId: this.registerCacheId,
                type: 1,
              });
              return;
            }
            const canBindAccounts = Rows;
            // const canBindAccounts = Rows.filter((item) => !item.hasBind);
            // if (canBindAccounts.length === 1) {
            //   const { companyId, userAccount } = canBindAccounts[0];
            //   this.plateFormReg({
            //     registerCacheId: this.registerCacheId,
            //     companyId,
            //     userAccount,
            //     isBind: true,
            //     type: 1,
            //   });
            //   return;
            // }
            this.canBindAccounts = canBindAccounts;
            this.dialogVisible = true;
          });
        } else {
          return false;
        }
      });
    },
    choose(companyIfo) {
      const { companyId, userAccount } = companyIfo;
      this.dialogVisible = false;
      this.plateFormReg({
        registerCacheId: this.registerCacheId,
        companyId,
        userAccount,
        isBind: true,
        type: 1,
      });
    },
    creatAccout() {
      this.plateFormReg({
        registerCacheId: this.registerCacheId,
        isBind: false,
        type: 1,
      });
    },
    async plateFormReg(refInfo) {
      const res = await plateFormReg({ ...refInfo });
      if (res) {
        const { company } = res;
        if (company) {
          this.$store.dispatch("setCache", company);
          this.$store.dispatch("changeAuthStatus", STATUS.COMINFO1);
        } else {
          const profileRes = await profileApi();
          if (profileRes.isManager) {
            this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
            this.$store.dispatch("getProfile");
            this.$router.replace("/home/index");
            // window.location.href = "./";
          } else {
            const ubeiH5Url =
              process.env.NODE_ENV == "development"
                ? "https://ubei-qa.threesoft.cn/h5/home"
                : location.origin + "/home/index";
            window.open(ubeiH5Url, "_self");
          }
        }
      }
      // plateFormReg({ ...refInfo }).then((res) => {
      //   const { company } = res;
      //   if (company) {
      //     this.$store.dispatch("setCache", company);
      //     this.$store.dispatch("changeAuthStatus", STATUS.COMINFO1);
      //   } else {
      //     this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
      //     this.$router.replace("/company/companyInfo");
      //   }
      // });
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
  },
};
</script>
