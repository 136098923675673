<template>
  <div class="h-loginFormH flex-1 p-s-xl box-border">
    <div class="flex items-center justify-start px-16 pt-8 border-2 border-warning rounded w-full m-auto mt-s-xxxl mb-s-xs cursor-pointer" @click="changeStatus(STATUS.COMINFO1)">
      <img style="width:77px;height: 90px;display: block;margin-right: 24px;" src="@/assets/img/login/login-auth.png"/>
      <span class="text-xl text-warning flex-1">认证企业</span>
      <i class="iconfont icon-line_arrowright text-warning text-xl"></i>
    </div>
    <div class="text-sm text-gray-900 cursor-pointer" @click="goHome">
      先跳过,以后再认证
    </div>
  </div>
</template>

<script>
import {STATUS} from "../../../store/index";

export default {
  data() {
    return {
      STATUS,
    };
  },
  methods: {
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    goHome() {
      this.$store.dispatch("getProfile");
      this.$router.replace("/home/index");
      // window.location.href = "./";
    },
  },
};
</script>
