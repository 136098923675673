<template>
  <div class="width100 height100 flex flex-center flex-direction-column overflow-hidden box-sizing padding4">
    <div class="flex flex-center flex-direction-column width50 height100 overflow-hidden">
      <div class="textColor03 fontSize20 font-weight text-align-center">
        绑定公司
      </div>
      <div class="margin-top4">
        <p class="fontSize14 textColor09">
          <span class="text-warning">*</span>
          <span>可选择下列您已在有备社区加入的公司绑定到当前公司，或下列公司都不是当前公司则可点击【立即认证】在有备社区认证当前公司！</span>
        </p>
      </div>
      <div class="margin-top4 flex1 overflow-auto width100">
        <div v-for="item in mutilCompaies" :key="item.id" class="flex flex-center margin-bottom2 border box-sizing padding2 border-radius2">
          <el-image class="w-56 h-56" src="../../../assets/logo.png" fit="cover"></el-image>
          <div class="flex1 margin-left2 fontSize14 textColor03">
            <p class="text-warning">{{ item.name }}</p>
            <p>主联系人-联系电话</p>
            <p>地址***********</p>
          </div>
          <div @click="bindCompany(item.id)" class="flex flex-center bg-warning textColor10 fontSize16 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
            绑定
          </div>
        </div>
      </div>
      <div class="margin-top4 flex flex-center width100 border box-sizing padding2 border-radius2">
        <div class="flex1 fontSize14 textColor09">
          以上公司都不是当前公司
        </div>
        <div @click="changeStatus(STATUS.COMINFO1)" class="flex flex-center bg-warning textColor10 fontSize16 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
          立即认证
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import {STATUS} from "../../../store/index";

export default {
  data() {
    return {
      STATUS,
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {},
    };
  },
  computed: {
    ...mapState(["mutilCompaies", "loginParams"]),
  },
  methods: {
    bindCompany(id) {
      this.$store.dispatch("login", {
        ...this.loginParams,
        type: 1, // 1 is for pc
        companyId: id,
      });
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
  },
};
</script>
