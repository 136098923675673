import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function getCategories(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/categories",
    method: "get",
    params
  });
}

export function getDict(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/dicts",
    method: "get",
    params
  });
}

export function getBrands(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/brands",
    method: "get",
    params
  });
}

export function getVehBrands(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/veh-brands",
    method: "get",
    params
  });
}
//车型点选车型数据
export function getVehModel(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/data/veh-model",
    method: "get",
    params
  });
}
//汽车品牌分组数据
export function getVehBrandsGroup(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/veh-brands/group",
    method: "get",
    params
  });
}
//整车分类数据
export function getDataClassGroup(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/data/class-group",
    method: "get",
    params
  });
}
//VIN解析
export function getVIN(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/vin",
    method: "get",
    params
  });
}
//助记符/联想词
export function getNamesApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/names",
    method: "get",
    params
  });
}
//图片VIN识别
export function getOrcVin(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/ocr/vin",
    headers: {
      "Content-Type": "text/plain; charset=utf-8"
    },
    method: "post",
    data: params
  });
}