<template>
  <div class="background-color10 footer-box padding-top3 padding-bottom3 box-shadow10">
    <div class="content-box margin-auto">
      <div class="flex flex-center fontSize12 textColor09">
        <span>北京有备智联科技有限责任公司</span>
        <span class="padding-left1 padding-right1">|</span>
        <span>地址：北京市大兴区圣和巷8号院5号楼3层332</span>
        <span class="padding-left1 padding-right1">|</span>
        <span>电话：18500029659</span>
      </div>
      <div class="flex flex-center fontSize12 textColor09 padding-top2">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          ICP备案号：京ICP备2022015551号-1
        </a>
      </div>
      <div class="flex flex-center fontSize12 textColor09 padding-top2 footer-box-more">
        <a target="_blank"
          href="https://www.12377.cn/?spm=a21bo.2017.1997523009.51.5af911d9c7kIvZ"
        >
          <img src="@/assets/img/report-12377.7d7125ec.png"/>
        </a>
        <a target="_blank" href="https://jbts.mct.gov.cn">
          <img src="@/assets/img/report-12318.9dcccd67.png"/>
        </a>
        <a target="_blank"
          href="http://www.shdf.gov.cn/shdf/channels/740.html?spm=a21bo.2017.1997523009.56.5af911d9c7kIvZ"
        >
          <img src="@/assets/img/report-anti-vice.5c537cdb.png"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tiny: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.footer-box .footer-box-more img {
  height: 32px;
  margin-left: 15px;
}
</style>