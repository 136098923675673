import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function getAddressList() {
  //获取区域 list
  let addressList = [];
  request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/basic/areas",
    method: "get"
  }).then(res => {
    let list = res;
    //拼接省数据入
    for (let item of list) {
      if (item.type == 2) {
        addressList.push({
          value: item.name,
          label: item.name,
          id: item.id,
          children: []
        })
      }
    }
    //省拼接好后循环拼接省对应的市
    for (let item of list) {
      for (let province of addressList) {
        if (item.parenId == province.id) {
          province.children.push({
            value: item.name,
            label: item.name,
          })
        }
      }
    }

  });
  return addressList;
}

export function getPositionAreaById(areaId, list) {
  //三次循环匹配需要的position
  for (let provinceIndex of list) {
    for (let cityIndex of provinceIndex.children) {
      if (areaId == cityIndex.value) {
        return {
          position: [provinceIndex.value, areaId],
          name: provinceIndex.label + "/" + cityIndex.label 
        }
      }
    }
  }
  //循环结束后如果没有返回数据，则在这里返回一条数据
  return {
    position: [],
    name: "",
  }
}