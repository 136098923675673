<template>
  <div class="flex flex-col  items-center">
    <div class="m-b-16">东方思锐-有备平台</div>
    <div id="qrcode" ref="qrcode"></div>
    <div class="m-t-16">请使用思锐APP扫描二维码登录</div>
  </div>
</template>

<script>
import { getErpLoginQrcodeUrlApi, tokenLogin } from "@/api/login";
import QRCode from "qrcodejs2";
// import io from "socket.io-client";
export default {
  data() {
    return {
      qrcode: null,
      socket: null,
      code: null,
      qrCodeUrl: null,
      userParams: {},
    };
  },
  mounted() {
    this.getErpLoginQrcode();
  },
  methods: {
    getErpLoginQrcode() {
      getErpLoginQrcodeUrlApi().then((res) => {
        this.qrCodeUrl = res?.qrCodeUrl;
        this.code = res?.code;
        this.initSocket();
        setTimeout(() => {
          this.qrcodeScan(this.qrCodeUrl);
        }, 200);
      });
    },
    initSocket() {
      const local = process.env.NODE_ENV === "development";
      const host = local ? "http://localhost:3000" : location.origin;
      //测试
      // const local = false;
      // const host = "https://ubei-qa.threesoft.cn";
      // console.log('io',io);
      this.socket = io(host, {
        debug: true,
        path: local ? "/socket.io" : "/api/s/im/ws",
        reconnectionDelay: 2000,
        transports: ["websocket"],
        query: {
          uid: this.code,
        },
      });
      console.log(this.socket, 333);
      this.socket.on("connect", (res) => {
        console.log("socket connect", res);
      });
      this.socket.on("systemMessage", (res) => {
        console.log("systemMessage", res);
        // let dataObj = {
        //   userAuthed: true, //# 用户是否已授权
        //   companyAuthed: true, //# 公司是否已授权
        //   token: "", //# 用户token，如果用户已授权，则可用此token登录
        //   platformKey: "m8", //# 三方平台Key，在绑定时原样传上来
        //   dbAccount: "", //# 账套，M8用，在绑定时原样传上来
        //   accountSetId: 0, //# 账套ID，云配用，在绑定时原样传上来
        //   userId: 0, //# 用户ID，云配用，在绑定时原样传上来
        //   companyId: 0, //# 公司/分店ID，云配/M8用，在绑定时原样传上来
        //   staffId: 0 //# 员工ID，云配/M8用，在绑定时原样传上来
        // };
        if (res.code == "ok") {
          let data = res.data;
          if (data?.token) {
            this.tokenLogin(data?.token);
          } else {
            if (!data?.userAuthed) {
              const {
                platformKey,
                dbAccount,
                accountSetId,
                userId,
                companyId,
                staffId,
              } = data;
              this.userParams = {
                platformKey,
                dbAccount,
                accountSetId,
                userId,
                companyId,
                staffId,
              };
              console.log("userParams", this.userParams);
              this.$message.error("用户未授权或未绑定，请先绑定！");
              let obj = {
                path: "/bangPhone",
                query: {
                  userParams: JSON.stringify(this.userParams),
                },
              };
              this.$router.push(obj);
            } else if (!data?.companyAuthed) {
              this.$message.error("公司未授权！");
              return;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    async tokenLogin(Token) {
      let params = {
        token: Token,
        type: 1,
      };
      let res = await tokenLogin(params);
      if (res) {
        let oldPath = sessionStorage.getItem("oldPath");
        let toPath = oldPath ? oldPath : "/home/index";
        this.$store.dispatch("getProfile");
        this.$router.replace(toPath);
      }
    },
    qrcodeScan(text) {
      //生成二维码
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      } else {
        this.qrcode = new QRCode("qrcode", {
          width: 172, // 设置宽度，单位像素
          height: 172, // 设置高度，单位像素
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //后景色
          text, // 设置二维码内容或跳转地址
        });
      }
    },
  },
};
</script>

<style></style>
