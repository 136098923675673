<template>
  <div class="width100 height100 flex flex-center flex-direction-column overflow-hidden box-sizing padding2">
    <div class="flex flex-center">
      <div class="flex flex-center textColor09">
        <span class="background-color10 border-radius100 flex flex-center step-num border">1</span>
        <span class="margin-left2 fontSize18 font-weight">认证公司</span>
      </div>
      <div class="margin-left6 padding-left6 margin-right6 padding-right6">
        <i class="iconfont icon-line_arrowright text-warning fontSize20 font-weight"></i>
      </div>
      <div class="flex flex-center text-warning">
        <span class="textColor10 bg-warning border-radius100 flex flex-center step-num">2</span>
        <span class="margin-left2 fontSize18 font-weight">完善资料</span>
      </div>
    </div>
    <div class="width100 m-auto flex1 overflow-auto padding-top2 padding-right2" style="height: 600px">
      <div class="fontSize14 text-warning pl-s-label margin-bottom2">
        <span>已完成公司认证，请完善公司资料</span>
        <span class="margin-left4">* 资料越完善,越容易被精准的推荐给用户!</span>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="125px"
        class="flex flex-1"
      >
        <div class="w-full overflow-hidden">
          <el-row>
            <el-col :span="12" class="space-y-8">
              <div class="text-base font-bold mb-s-l pl-s-label">基础信息</div>
              <el-form-item prop="mobile" class="w-full" label="公司logo">
                <div class="upload_file">
                  <UploadFileImg
                    :maxSize="1"
                    listType="picture-card"
                    :limit="1"
                    :fileList="companyLogoList"
                    :isMultiple="false"
                    :showFileList="true"
                    :hideFlag="true"
                    @getFileList="
                      (fileList) => getFileList(fileList, 'companyLogoList')
                    "
                  />
                </div>
              </el-form-item>
              <el-form-item class="w-full" label="公司图片">
                <div class="upload_file">
                  <UploadFileImg
                    :maxSize="4"
                    listType="picture-card"
                    :limit="4"
                    :fileList="imagesList"
                    :isMultiple="false"
                    :showFileList="true"
                    :hideFlag="true"
                    @getFileList="
                      (fileList) => getFileList(fileList, 'imagesList')
                    "
                  />
                </div>
              </el-form-item>
              <el-form-item class="w-full" label="主营配件类别">
                <el-cascader
                  v-model="ruleForm.categories"
                  :options="categories"
                  :props="{
                    multiple: true,
                    label: 'name',
                    value: 'name',
                  }"
                  :show-all-levels="false"
                  clearable
                  class="w-full"
                ></el-cascader>
              </el-form-item>
              <el-form-item class="w-full" label="主营车型品牌">
                <el-select
                  v-model="ruleForm.vehBrands"
                  :remote-method="remoteVehBrands"
                  filterable
                  multiple
                  remote
                  clearable
                >
                  <el-option
                    v-for="(item, index) in brands"
                    :key="index"
                    :label="item.name"
                    :value="item.id.toString()"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="w-full" label="主营配件品牌">
                <el-select
                  v-model="ruleForm.brands"
                  :remote-method="remoteBrands"
                  filterable
                  remote
                  multiple
                  clearable
                >
                  <el-option
                    v-for="(item, index) in brands"
                    :key="index"
                    :label="item.name"
                    :value="item.id.toString()"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="w-full" label="公司网站" prop="website">
                <el-input
                  v-model="ruleForm.website"
                  type="text"
                  maxlength="50"
                  placeholder="最多50个字"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="w-full" label="公司公众号">
                <UploadFileImg
                  :maxSize="1"
                  listType="picture-card"
                  :limit="1"
                  :fileList="wechatOffiCccountImageList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="
                    (fileList) =>
                      getFileList(fileList, 'wechatOffiCccountImageList')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="12" class="space-y-8">
              <div class="text-base font-bold mb-s-l pl-s-label">联系方式</div>
              <el-form-item class="w-full" label="联系人1">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-input
                      v-model="ruleForm.contacts1"
                      type="text"
                      maxlength="50"
                      placeholder="最多50个字"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="15">
                    <el-input
                      v-model="ruleForm.phone1"
                      type="text"
                      maxlength="11"
                      placeholder="请输入手机号"
                    >
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item class="w-full" label="联系人2">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-input
                      v-model="ruleForm.contacts2"
                      type="text"
                      maxlength="50"
                      placeholder="最多50个字"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="15">
                    <el-input
                      v-model="ruleForm.phone2"
                      type="text"
                      maxlength="11"
                      placeholder="请输入手机号"
                    >
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item class="w-full" label="联系人3">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-input
                      v-model="ruleForm.contacts3"
                      type="text"
                      maxlength="50"
                      placeholder="最多50个字"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="15">
                    <el-input
                      v-model="ruleForm.phone3"
                      type="text"
                      maxlength="11"
                      placeholder="请输入手机号"
                    >
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item class="w-full" label="微信">
                <el-input
                  v-model="ruleForm.wechat"
                  type="text"
                  maxlength="50"
                  placeholder="最多50个字"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="w-full" label="微信名片">
                <div class="upload_file">
                  <UploadFileImg
                    :maxSize="4"
                    listType="picture-card"
                    :limit="4"
                    :fileList="wechatImages"
                    :isMultiple="false"
                    :showFileList="true"
                    :hideFlag="true"
                    @getFileList="
                      (fileList) => getFileList(fileList, 'wechatImages')
                    "
                  />
                </div>
              </el-form-item>
              <el-form-item class="w-full" label="QQ">
                <el-input
                  v-model="ruleForm.qq"
                  type="text"
                  maxlength="50"
                  placeholder="最多50个字"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="w-full" label="QQ名片">
                <div class="upload_file">
                  <UploadFileImg
                    :maxSize="4"
                    listType="picture-card"
                    :limit="4"
                    :fileList="qqImages"
                    :isMultiple="false"
                    :showFileList="true"
                    :hideFlag="true"
                    @getFileList="
                      (fileList) => getFileList(fileList, 'qqImages')
                    "
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="flex flex-center margin-top4 margin-bottom4">
        <div @click="nextTime" class="background-color10 textColor03 fontSize14 margin-left6 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4 border">
          先跳过,下次认证
        </div>
        <div @click="submitForm()" class="bg-warning textColor10 fontSize14 margin-left6 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
          保存
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {STATUS} from "../../../store/index";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import {getCategories, getBrands, getVehBrands} from "@/api/basic";
import {getCompany, updateCompany} from "@/api/company";

export default {
  components: {
    UploadFileImg,
  },
  watch: {
    companyLogoList: {
      handler: function (newVal) {
        this.ruleForm.logoImage =
          newVal && newVal.length > 0 ? newVal[0].origin : "";
      },
      deep: true,
    },
    wechatOffiCccountImageList: {
      handler: function (newVal) {
        this.ruleForm.wechatOffiCccountImage =
          newVal && newVal.length > 0 ? newVal[0].origin : "";
      },
      deep: true,
    },
    imagesList: {
      handler: function (newVal) {
        this.ruleForm.images =
          newVal && newVal.length > 0 ? newVal.map((item) => item.origin) : [];
      },
      deep: true,
    },
    wechatImages: {
      handler: function (newVal) {
        this.ruleForm.wechatImages =
          newVal && newVal.length > 0 ? newVal.map((item) => item.origin) : "";
      },
      deep: true,
    },
    qqImages: {
      handler: function (newVal) {
        this.ruleForm.qqImages =
          newVal && newVal.length > 0 ? newVal.map((item) => item.origin) : "";
      },
      deep: true,
    },
  },
  data() {
    return {
      STATUS,
      companyLogoList: [],
      imagesList: [],
      wechatOffiCccountImageList: [],
      wechatImages: [],
      qqImages: [],
      ruleForm: {
        website: "",
        wechatOffiCccount: "",
        contacts1: "",
        contacts2: "",
        contacts3: "",
        phone1: "",
        phone2: "",
        phone3: "",
        wechat: "",
        wechatImages: [],
        qqImages: [],
      },
      rules: {},
      categories: [],
      brands: [],
      vehBrands: [],
    };
  },
  mounted() {
    this.remoteCategories();
    this.remoteBrands();
    this.remoteVehBrands();
    this.init();
  },
  methods: {
    init() {
      getCompany().then((res) => {
        this.ruleForm = {...res};
      });
    },
    getFileList(fileList, key) {
      this[key] = fileList;
    },
    remoteCategories(query) {
      getCategories({value: query})
        .then((res) => {
          this.categories = res || [];
        })
        .catch(() => {
          this.categories = [];
        });
    },
    remoteBrands(query) {
      getBrands({value: query})
        .then((res) => {
          this.brands = res || [];
        })
        .catch(() => {
          this.brands = [];
        });
    },
    remoteVehBrands(query) {
      getVehBrands({value: query})
        .then((res) => {
          this.vehBrands = res || [];
        })
        .catch(() => {
          this.vehBrands = [];
        });
    },
    nextTime() {
      this.$store.dispatch("getProfile");
      this.$router.replace("/home/index");
      this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
      // window.location.href = "./";
    },
    submitForm() {
      getCompany().then((res) => {
        updateCompany({
          ...res,
          ...this.ruleForm,
        }).then(() => {
          this.$store.dispatch("getProfile");
          this.$router.replace("/home/index");
          // window.location.href = "./";
        });
      });
    },
  },
};
</script>
<style scoped>
.step-num {
  width: 20px;
  height: 20px;
}
</style>