<template>
  <div class="h-loginFormH p-s-xl m-auto box-border bg-white">
    <div class="text-xl font-bold mb-s-xxs">登录</div>
    <div class="text-base mb-s-s">你好！有备平台欢迎您，请登录~</div>
    <div class="space-x-8 w-2/3 flex justify-between items-center mb-16">
      <div
        class="w-1/2 text-center cursor-pointer select-none  border-b-4 border-opacity-0"
        :class="{
          'text-warning border-warning border-opacity-100 ': currentTab === 0,
        }"
        @click="currentTab = 0"
      >
        账号密码登录
      </div>
      <div class=" border-r-2 border-black-200 h-16"></div>
      <div
        class="w-1/2 text-center cursor-pointer select-none border-b-4 border-opacity-0"
        :class="{
          'text-warning border-warning border-opacity-100 ': currentTab === 1,
        }"
        @click="currentTab = 1"
      >
        手机验证登录
      </div>
    </div>
    <el-form
      v-show="currentTab === 0"
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs "
    >
      <el-form-item prop="account" class="w-full">
        <el-input
          v-model="ruleForm.account"
          class="noborder"
          maxlength="6"
          type="text"
          clearable
          placeholder="* 请输入账号"
          @change="updateTemInfo"
        >
          <template #prefix>
            <i class="iconfont icon-login_tel"></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" class="w-full">
        <el-input
          v-model="ruleForm.password"
          class="noborder"
          type="password"
          @change="updateTemInfo"
          show-password
          maxLength="16"
          placeholder="* 请输入密码"
          ><template #prefix>
            <i class="iconfont icon-login_password"></i> </template
        ></el-input>
      </el-form-item>
    </el-form>
    <el-form
      v-show="currentTab === 1"
      ref="ruleFormRef2"
      :model="ruleForm2"
      status-icon
      :rules="rules2"
      label-width="0px"
      class="mb-s-xs "
    >
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm2.phone"
          maxlength="11"
          class="noborder"
          type="text"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm2.smsCaptcha"
          type="text"
          class="noborder"
          maxlength="6"
          placeholder="请输入验证码"
          ><template #append>
            <MsgCode :mobile="ruleForm2.phone" :type="'login'" />
          </template>
        </el-input> </el-form-item
    ></el-form>
    <div class="flex justify-between w-full text-sm mb-s-m">
      <span
        v-show="currentTab === 0"
        class="cursor-pointer text-gray-900"
        @click="changeStatus(STATUS.RESETPASS)"
        >忘记密码?</span
      >
      <span v-show="currentTab === 1"> </span>
      <span
        class="cursor-pointer text-gray-900"
        @click="changeStatus(STATUS.REG)"
        >还没有账号,去注册</span
      >
    </div>
    <button
      :disabled="!isAgree"
      class="w-full rounded-full py-s-xs text-center text-gray-100  mb-s-m"
      :class="{
        'bg-warning': isAgree,
        'bg-gray-900': !isAgree,
      }"
      @click="submit"
    >
      立即登录
    </button>
    <div class="w-full text-center mb-8 text-gray-900">使用三方账号登录</div>
    <div class="flex justify-center item-center space-x-16 mb-s-l w-full">
      <div
        class="w-32 h-32 bg-success rounded-full text-center cursor-pointer"
        @click="wechatLogin"
      >
        <i
          class="iconfont icon-weixin w-56 h-56 text-warnings text-xl"
          style="line-height:32px;"
        ></i>
      </div>
      <!-- <div
        class="w-32 h-32 bg-white border border-success rounded-full flex justify-center items-center cursor-pointer"
        @click="wechatLogin"
      >
        <i class="iconfont icon-weixin text-xl text-success "></i>
      </div>
      <div
        class="w-32 h-32 bg-white  rounded-full flex justify-center items-center cursor-pointer"
        style="border:1px solid pink;"
        @click="threesoftLogin"
      >
        <img
          src="@/assets/img/login/threesoft.png"
          alt=""
          style="width:18px;"
        />
      </div> -->
    </div>
    <div class="text-center w-full text-sm text-gray-900">
      <i
        v-if="isAgree"
        class="iconfont icon-radio_sel cursor-pointer text-main"
        @click="isAgree = !isAgree"
      ></i>
      <i
        v-else
        class="iconfont icon-radio_nor cursor-pointer"
        @click="isAgree = !isAgree"
      ></i>
      <span> 登录即代表您已同意</span>
      <span class="text-black-300"
        ><span class="text-warning cursor-pointer" @click="showMsg(1)"
          >《服务协议》</span
        >和<span class="text-warning cursor-pointer" @click="showMsg(2)"
          >《隐私政策》</span
        >
      </span>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="35%">
      <p>当前手机号已绑定以下平台账号，请选择你当前要登录的平台账号:</p>
      <div
        class="space-x-8 mb-8 mt-8"
        v-for="account in accountList"
        :key="account.id"
      >
        <span class="text-warning inline-block w-165">{{
          `${account.account} ${account.companyName}-${account.name}`
        }}</span
        ><el-button
          type="primary"
          size="mini"
          @click="chooseUserLogin(account.id)"
          >登录</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="服务协议" :visible.sync="showProto" width="65%">
      <Proto />
    </el-dialog>
    <el-dialog title="隐私政策" :visible.sync="showPravicy" width="65%">
      <Pravicy />
    </el-dialog>
    <el-dialog
      title="思锐账号登录"
      :visible.sync="showThreesoft"
      width="400px"
      center
    >
      <SrCodeDialog />
    </el-dialog>
  </div>
</template>

<script>
import { STATUS } from "../../../store/index";
import {
  mobileValidate,
  passwordValidate,
  captchaValidate,
} from "@/utils/validate";
import { redirectUrl } from "envConfigPath";
import { codeUrl } from "@/api/login";
import MsgCode from "@/components/msgCode/msgCode";
import { phoneLoginApi } from "@/api/login";
import Proto from "./Proto.vue";
import Pravicy from "./Pravicy.vue";
import SrCodeDialog from "./srCodeDialog.vue";
export default {
  components: {
    MsgCode,
    Proto,
    Pravicy,
    SrCodeDialog,
  },
  data() {
    return {
      isAgree: false,
      dialogVisible: false,
      showProto: false,
      showPravicy: false,
      showThreesoft: false,
      STATUS,
      currentTab: 0,
      accountList: [],
      ruleForm: {
        account: "",
        password: "",
      },
      ruleForm2: {
        phone: "",
        smsCaptcha: "",
      },
      rules: {
        account: [{ required: true, message: "账号不能为空", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: passwordValidate, trigger: "blur" },
        ],
      },
      rules2: {
        phone: [{ validator: mobileValidate, trigger: "blur" }],
        smsCaptcha: [{ validator: captchaValidate, trigger: "blur" }],
      },
    };
  },
  methods: {
    updateTemInfo() {},
    async submit() {
      if (this.currentTab === 0) {
        this.$refs.ruleFormRef.validate((valid) => {
          if (valid) {
            this.$store.dispatch("login", {
              ...this.ruleForm,
              type: 1, // 1 is for pc
            });
          } else {
            return false;
          }
        });
      } else {
        const res = await phoneLoginApi({
          ...this.ruleForm2,
          type: 1, // 登录终端类型 1: 网页 2: APP 3: H5
        });
        if (res && res.code === "multi-users") {
          this.dialogVisible = true;
          this.accountList = res.data;
          console.log("执行验证码登录逻辑,多账户需要弹窗选择账户");
        } else {
          const { hasCompany } = res;
          // 店铺未认证,跳转至认证
          if (!hasCompany) {
            this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
            return;
          }
          this.$store.dispatch("setProfile", res);
          // 正常登录流程
          setTimeout(() => {
            this.$router.replace("/home/index");
            // window.location.href = "./";
          }, 500);
        }
      }
    },
    async chooseUserLogin(id) {
      phoneLoginApi({
        ...this.ruleForm2,
        id,
        type: 1, // 登录终端类型 1: 网页 2: APP 3: H5
      }).then((res) => {
        this.$store.dispatch("getProfile");
        setTimeout(() => {
          this.$router.replace("/home/index");
          // window.location.href = "./";
        }, 500);
      });
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    showMsg(type) {
      if (type === 1) {
        this.showProto = true;
        return;
      }
      this.showPravicy = true;
    },
    wechatLogin() {
      codeUrl({
        online: true,
        redirectUrl: encodeURIComponent(redirectUrl),
      }).then((res) => {
        window.open(res);
      });
    },
    threesoftLogin() {
      this.showThreesoft = true;
    },
  },
};
</script>
