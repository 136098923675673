<template>
  <div class="width100 height100 flex flex-center overflow-hidden box-sizing padding2">
    <div class="flex flex-center flex-direction-column width30">
      <div class="textColor03 fontSize20 font-weight text-align-center">
        加入公司
      </div>
      <div class="fontSize16 text-warning text-align-center margin-top4">
        输入公司邀请码则可申请加入公司
      </div>
      <div class="width100 margin-top4">
        <el-input
          v-model="inviteCode"
          class="width100"
          type="text"
          maxLength="8"
          clearable
          placeholder="公司邀请码"
          @input="search"
        ></el-input>
      </div>
      <div class="flex flex-center margin-top4" v-if="companyInfo && companyInfo.name">
        <el-image class="w-32 h-32" :src="companyInfo.logoImage" fit="cover"></el-image>
        <span class="fontSize14 text-warning margin-left4">{{ companyInfo.name }}</span>
      </div>
      <div class="width100 flex flex-center margin-top4">
        <div @click="nextTime" class="background-color10 textColor03 fontSize14 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4 border">
          先跳过,下次再加
        </div>
        <div class="flex1"></div>
        <div @click="changeStatus(STATUS.COMINFO1)" class="background-color10 textColor03 fontSize14 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4 border">
          我要认证公司
        </div>
      </div>
      <div class="width100 flex flex-center margin-top4">
        <div @click="applyStaff" class="flex1 flex flex-center bg-warning textColor10 fontSize16 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
          发起申请
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {STATUS} from "../../../store/index";
import {getInviteInfo} from "@/api/company";
import {applyStaff} from "@/api/account";

export default {
  data() {
    return {
      STATUS,
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {},
      companyInfo: {},
      inviteCode: "",
    };
  },
  methods: {
    search() {
      console.log(this.inviteCode);
      if (this.inviteCode && this.inviteCode.length === 8) {
        getInviteInfo({
          staffInviteCode: this.inviteCode,
        }).then((res) => {
          this.companyInfo = {...res};
        });
      }
    },
    nextTime() {
      this.$store.dispatch("getProfile");
      this.$router.replace("/home/index");
      this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
      // window.location.href = "./";
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    applyStaff() {
      applyStaff({
        staffInviteCode: this.inviteCode,
      }).then(() => {
        this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
        this.$message.success("已申请加入,请等待管理员处理");
      });
    },
  },
};
</script>
