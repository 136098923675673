<template>
  <div class="space-y-32 w-1/2 m-auto pt-56">
    <div class="text-xl font-bold text-center">忘记密码</div>
    <div class="w-1/2 m-auto">
      <div
        v-show="step === 1 || step === 2"
        class="text-xl  flex w-full m-auto justify-center text-center mb-s-s space-x-8"
      >
        <div class="flex items-center">
          <span
            class=" text-sm rounded-full border border-black-200 inline-block  w-16 h-16 mr-8"
            :class="{
              'bg-warning text-white border-warning': step === 1,
              'text-black-100': step === 2,
            }"
            style="line-height: 16px;"
          >1</span
          >
          <span
            :class="{
              'text-warning': step === 1,
            }"
          >身份验证</span
          >
        </div>
        <i class="iconfont icon-line_arrowright text-warning text-xl"></i>
        <div class="flex items-center">
          <span
            class=" text-sm rounded-full inline-block border border-black-200  w-16 h-16 mr-8"
            :class="{
              'bg-warning text-white border-warning': step === 2,
              'text-black-100': step === 1,
            }"
            style="line-height: 16px;"
          >2</span
          >
          <span
            :class="{
              'text-warning': step === 2,
            }"
          >重置密码</span
          >
        </div>
      </div>
      <el-form
        v-show="step === 1"
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="0px"
        class="mb-s-xs"
      >
        <el-form-item prop="phone" class="w-full">
          <el-input
            v-model="ruleForm.phone"
            maxlength="11"
            class="noborder"
            type="text"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full" prop="smsCaptcha">
          <el-input
            v-model="ruleForm.smsCaptcha"
            type="text"
            class="noborder"
            maxlength="6"
            placeholder="请输入验证码"
          >
            <template #append>
              <MsgCode :mobile="ruleForm.phone" :type="'resetPassword'"/>
            </template>
          </el-input>
        </el-form-item>
        <p
          class="text-warning mb-8 cursor-pointer"
          style="text-decoration: underline;"
          @click="changeStatus(STATUS.LOGIN)"
        >
          又想起来了,去登录
        </p>
        <el-form-item class="w-full" prop="field3">
          <div
            class=" text-white bg-warning rounded text-center cursor-pointer"
            @click="goToStep2"
          >
            下一步,重置密码
          </div>
        </el-form-item>
      </el-form>
      <el-form
        v-show="step === 2"
        ref="ruleFormRef2"
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        label-width="0px"
        class="mb-s-xs"
      >
        <el-form-item prop="id">
          <el-select v-model="ruleForm2.id" placeholder="选择手机号绑定的账号">
            <el-option
              v-for="item in accounts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="password" class="w-full">
          <el-input
            v-model="ruleForm2.password"
            type="password"
            maxlength="16"
            placeholder="请输入密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="repeatPassword" class="w-full">
          <el-input
            v-model="ruleForm2.repeatPassword"
            type="password"
            maxlength="16"
            placeholder="请输入密码"
          >
          </el-input>
        </el-form-item>
        <p
          class="text-warning mb-8 cursor-pointer"
          style="text-decoration: underline;"
          @click="changeStatus(STATUS.LOGIN)"
        >
          又想起来了,去登录
        </p>
        <el-form-item class="w-full" prop="field3">
          <div
            class=" text-white bg-warning rounded text-center cursor-pointer"
            @click="submitForm"
          >
            确定
          </div>
        </el-form-item>
      </el-form>
      <div v-show="step === 3">
        <p class="text-center mb-8">密码变更成功</p>
        <p class="text-center mb-8">
          您的密码已经变更成功，下次请用新密码登录。
        </p>
        <div
          class=" text-white bg-warning rounded text-center cursor-pointer"
          @click="changeStatus(STATUS.LOGIN)"
        >
          去登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {changePassCheckApi, changePassApi} from "@/api/login";
import {
  mobileValidate,
  captchaValidate,
  passwordValidate,
} from "@/utils/validate";
import MsgCode from "@/components/msgCode/msgCode";
import {STATUS} from "../../../store/index";

export default {
  components: {
    MsgCode,
  },
  data() {
    const samePasswordValidater = (rule, value, callback) => {
      if (this.ruleForm2.password !== this.ruleForm2.repeatPassword) {
        return callback(new Error("两次输入密码不一致"));
      }
      return callback();
    };
    return {
      STATUS,
      step: 1,
      accounts: [],
      ruleForm: {
        phone: "",
        smsCaptcha: "",
      },
      ruleForm2: {
        password: "",
        repeatPassword: "",
      },
      rules: {
        phone: [{validator: mobileValidate, trigger: "blur"}],
        smsCaptcha: [{validator: captchaValidate, trigger: "blur"}],
      },
      rules2: {
        id: [
          {
            required: true,
            message: "请选择手机号绑定的账号",
            trigger: "blur",
          },
        ],
        password: [{validator: passwordValidate, trigger: "blur"}],
        repeatPassword: [
          {validator: passwordValidate, trigger: "blur"},
          {validator: samePasswordValidater, trigger: "blur"},
        ],
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
    };
  },
  methods: {
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.countyId = dataObj.areaIds[2];
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    goToStep2() {
      // 验证
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          changePassCheckApi(this.ruleForm).then((res) => {
            this.step = 2;
            this.accounts = res;
          });
        } else {
          return false;
        }
      });
    },
    submitForm() {
      // 验证
      this.$refs.ruleFormRef2.validate((valid) => {
        if (valid) {
          changePassApi({
            id: this.ruleForm2.id,
            phone: this.ruleForm.phone,
            password: this.ruleForm2.password,
          }).then(() => {
            this.step = 3;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
