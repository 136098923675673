<template>
  <div>
    <p class=" mb-8">
      当前手机号已绑定以下平台账号,请选择你当前要绑定的平台账号
    </p>
    <div>
      <div
        v-for="(item, index) in accounts"
        class="flex justify-between items-center bg-gray-200 rounded-t-sm p-s-s mb-8"
      >
        <div>
          <p class=" font-bold">{{ item.companyName }}</p>
          <p>{{ item.userName }} {{ item.userAccount }}</p>
          <p class=" text-warning" v-if="item.hasBind">
            * 已绑定其他ERP账号,当前不可绑定!
          </p>
        </div>
        <div v-if="!item.hasBind">
          <div
            class=" bg-warning text-gray-100 px-16 py-2 rounded-full text-lg"
            @click="choose(item)"
          >
            绑定
          </div>
        </div>
      </div>
      <div
        class="flex justify-between items-center bg-warning bg-opacity-20 rounded-t-sm p-s-s"
        @click="creatAccout()"
      >
        <div>
          <p class=" text-warning">创建一个新的平台账号进行绑定</p>
        </div>
        <div>
          <i class="iconfont icon-line_arrowright text-warning text-xl"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accounts: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    choose(item) {
      this.$emit("choose", item);
    },
    creatAccout() {
      this.$emit("creatAccout");
    },
  },
};
</script>
