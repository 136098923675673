<template>
  <div class="width100 height100 flex flex-center flex-direction-column overflow-hidden box-sizing padding4">
    <div class="flex flex-center flex-direction-column width80 height100">
      <div class="textColor03 fontSize20 font-weight text-align-center">
        提示
      </div>
      <div class="margin-top4 flex1">
        <p>
          当前公司名称已在有备社区中被占用，系统无法为您在有备社区上做自动认证，请点击下方【去认证】按钮前往手动认证，认证时请修改公司名称！
        </p>
        <p class="fontSize13 textColor09 margin-top2">
          注：认证时修改的公司名称不会影响ERP中的公司名称，只会影响您在有备社区上的公司名称！
        </p>
      </div>
      <div class="margin-top4 flex flex-center">
        <div class="flex1 flex flex-center bg-warning textColor10 fontSize16 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
          去认证({{ count }}s)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {},
      count: 0
    };
  },
};
</script>
