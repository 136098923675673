<template>
  <div class="width100 header-box box-shadow10">
    <div class="content-box margin-auto flex align-items-center fontSize14">
      <div class="flex flex-center cursor-pointer" @click="redirect('/home')">
        <img class="w-32 h-32" src="@/assets/logo.png" alt="logo" />
        <span class="text-warning">有备首页</span>
      </div>
      <div
        v-if="!tiny"
        class="flex1 flex align-items-center flex-justify-start padding-left6 divide-solid divide-opacity-30 divide-black-100 divide-x-2"
      >
        <span
          class="cursor-pointer  padding-right2 actives05"
          @click="inquirySheetManagement"
          >询报价管理</span
        >
        <span
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="redirect('/allianceManage/list')"
          >联盟管理</span
        >
        <span
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="redirect('/userCenter/need')"
          >求购历史</span
        >
        <span
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="redirect('/userCenter/focus')"
          >关注店铺</span
        >
        <span
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="redirect('/userCenter/collection')"
          >收藏商品</span
        >
        <span
          v-if="profile.id && !profile.hasCompany"
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="auth"
          >商家入驻</span
        >
        <span
          v-if="profile.id && profile.hasCompany"
          class="cursor-pointer padding-left2 padding-right2 actives05"
          @click="$router.replace('/company/companyInfo')"
          >管理中心</span
        >
        <div class="areaPicker padding-left2">
          <SelectArea :areaIds="areaIds" @change="areaChange" />
        </div>
      </div>
      <el-avatar
        v-if="!tiny"
        :size="'small'"
        :src="
          profile.avatar ||
            profile.wechatAvatar ||
            'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
        "
      ></el-avatar>
      <el-dropdown @command="redirect" v-if="profile.id">
        <div class="flex flex-center">
          <div v-if="profile.id" class="margin-left2">
            {{ name }}
          </div>
          <i class="el-icon-caret-bottom margin-left2"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="'/userCenter/account'"
            >账号管理</el-dropdown-item
          >
          <el-dropdown-item :command="'/userCenter/address'"
            >地址管理</el-dropdown-item
          >
          <el-dropdown-item :command="'/userCenter/focus'"
            >关注店铺</el-dropdown-item
          >
          <el-dropdown-item :command="'/userCenter/collection'"
            >收藏商品</el-dropdown-item
          >
          <el-dropdown-item :command="'/userCenter/history'"
            >浏览记录</el-dropdown-item
          >
          <el-dropdown-item :command="'/userCenter/need'"
            >求购记录</el-dropdown-item
          >
          <el-dropdown-item :command="'logout'">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else>
        <div
          v-if="!tiny"
          class="text-warning margin-left2 cursor-pointer actives05"
          @click="toLogin"
        >
          登录/注册
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getLocation } from "@/utils/location";
import { STATUS } from "../../../store/index";
import { loginOut } from "@/api/login";
import SelectArea from "@/components/selectArea/SelectAreaLevel2.vue";
export default {
  props: {
    tiny: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectArea,
  },
  data() {
    return {
      STATUS,
      areaIds: [],
    };
  },
  computed: {
    userInfo() {
      return this.profile;
    },
    ...mapState({
      name: (state) => state.profile.name,
      profile: (state) => state.profile,
    }),
  },
  mounted() {
    this.initAddress();
  },
  methods: {
    initAddress() {
      const locationStr = localStorage.getItem("location");
      if (!locationStr) {
        getLocation();
        setTimeout(() => {
          if (locationStr) {
            const { province, city } = JSON.parse(locationStr);
            this.areaIds = [province, city];
          }
        }, 1200);
      } else {
        const { province, city } = JSON.parse(locationStr);
        this.areaIds = [province, city];
      }
    },
    redirect(url) {
      if (url !== "/home" && !this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      if (url === "logout") {
        loginOut().then(() => {
          sessionStorage.removeItem("customerQueryObj");
          sessionStorage.removeItem("userInfo");
          sessionStorage.removeItem("erpType");
          sessionStorage.removeItem("Token");
          this.$store.dispatch("setProfile", {});
          this.$router.replace("/home/index");
          window.location.href = "./";
        });
        return;
      }
      this.$router.push(url);
    },
    areaChange(dataObj) {
      this.areaId = dataObj;
    },
    auth() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
    toLogin() {
      this.$store.dispatch("changeAuthStatus", STATUS.LOGIN);
      this.$router.push("/login");
    },
    inquirySheetManagement() {
      // if (window.SOURCE === "erp") {
        this.redirect("/trading/offerManage");
      // } else {
      //   this.redirect("/home/myEnquiry");
      // }
    }
  },
};
</script>
<style scoped>
.header-box {
  height: 32px;
  background: #e3e3e3;
}
</style>
