<template>
  <div class="width100 height100 overflow-hidden flex flex-direction-column">
    <div class="width100">
      <Header :tiny="true" class="z-index3001"/>
    </div>
    <div class="flex1 width100 background-color26 flex flex-center box-sizing padding4 overflow-auto">
      <div v-if="showLRALayout" class="content-box margin-auto flex flex-center background-color10 border-radius2 overflow-hidden box-shadow10">
        <div class="login-bg"></div>
        <div class="flex1 flex flex-direction-column">
          <LoginForm v-if="authStatus === STATUS.LOGIN"/>
          <RegForm v-if="authStatus === STATUS.REG"/>
          <NeedAuth v-if="authStatus === STATUS.AUTH"/>
        </div>
      </div>
      <div v-else class="content-box margin-auto background-color10 border-radius2 overflow-hidden box-shadow10 width100 height100">
        <ComInfo1 v-if="authStatus === STATUS.COMINFO1"/>
        <ComInfo2 v-if="authStatus === STATUS.COMINFO2"/>
        <InviteCode v-if="authStatus === STATUS.INVITECODE"/>
        <BeenTaked v-if="authStatus === STATUS.BEENTAKED"/>
        <BindCompany v-if="authStatus === STATUS.NEEDBINDCOMPANY"/>
        <ResetPassword v-if="authStatus === STATUS.RESETPASS"/>
        <AuthBindPhone v-if="authStatus === STATUS.AUTHBINDPHONE"/>
      </div>
    </div>
    <div class="width100">
      <Footer :tiny="true"/>
    </div>
  </div>
</template>

<script>
import Header from "../userCenter/components/Header.vue";
import Footer from "../home/components/footer.vue";
import {mapState} from "vuex";
import LoginForm from "./components/LoginForm.vue";
import RegForm from "./components/RegForm.vue";
import NeedAuth from "./components/NeedAuth.vue";
import ComInfo1 from "./components/ComInfo1.vue";
import ComInfo2 from "./components/ComInfo2.vue";
import InviteCode from "./components/InviteCode.vue";
import BeenTaked from "./components/BeenTaked.vue";
import BindCompany from "./components/BindCompany.vue";
import ResetPassword from "./components/ResetPassword.vue";
import AuthBindPhone from "./components/AuthBindPhone.vue";
import {STATUS} from "../../store/index";

export default {
  components: {
    Header,
    Footer,
    LoginForm,
    RegForm,
    NeedAuth,
    ComInfo1,
    ComInfo2,
    InviteCode,
    BeenTaked,
    BindCompany,
    ResetPassword,
    AuthBindPhone,
  },
  data() {
    return {
      STATUS,
    };
  },
  computed: {
    ...mapState({
      authStatus: (state) => state.authStatus,
    }),
    showLRALayout() {
      return (
        this.authStatus === STATUS.LOGIN ||
        this.authStatus === STATUS.REG ||
        this.authStatus === STATUS.AUTH
      );
    },
  },
};
</script>
<style lang="css" scoped>
/deep/ .content-box {
  width: 1000px;
  min-width: 1000px;
  max-width: 1000px;
  box-sizing: border-box;
}

.login-bg {
  width: 480px;
  height: 550px;
  background-image: url("../../assets/img/login/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
