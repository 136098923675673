<template>
  <div class="width100 height100 flex flex-center flex-direction-column overflow-hidden box-sizing padding2">
    <div class="flex flex-center">
      <div class="flex flex-center text-warning">
        <span class="textColor10 bg-warning border-radius100 flex flex-center step-num">1</span>
        <span class="margin-left2 fontSize18 font-weight">认证公司</span>
      </div>
      <div class="margin-left6 padding-left6 margin-right6 padding-right6">
        <i class="iconfont icon-line_arrowright text-warning fontSize20 font-weight"></i>
      </div>
      <div class="flex flex-center textColor09">
        <span class="background-color10 border-radius100 flex flex-center step-num border">2</span>
        <span class="margin-left2 fontSize18 font-weight">完善资料</span>
      </div>
    </div>
    <div class="width60 m-auto flex1 overflow-auto padding-top2 padding-right2">
      <div class="fontSize14 text-warning pl-s-label">
        完成公司认证后可以成为有备社区的商家支持上传配件库存价格到有备社区上。
      </div>
      <div class="fontSize14 text-warning pl-s-label">
        请完善公司资料，*标记为必填项！
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        :show-message="false"
        label-width="125px"
        class="flex flex-1"
      >
        <div class="w-full space-y-8">
          <el-form-item class="w-full" label="公司类型" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="'dealer'">经销商</el-radio>
              <el-radio :label="'manufacturer'">生产商</el-radio>
              <el-radio :label="'garage'">修理厂</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="w-full" label="公司名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              type="text"
              maxlength="50"
              placeholder="最多50个字"
            ></el-input>
          </el-form-item>
          <el-form-item class="w-full" label="联系人" prop="contacts">
            <el-input
              v-model="ruleForm.contacts"
              type="text"
              maxlength="20"
              placeholder="请输入联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item class="w-full" label="联系电话" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              maxlength="11"
              type="text"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item class="w-full" label="所在地区" prop="areaId">
            <SelectArea
              style="width: 100%"
              :areaData="ruleForm.areaIds"
              @change="areaChange"
            />
          </el-form-item>
          <el-form-item class="w-full" label="详细地址" prop="address">
            <el-input
              v-model="ruleForm.address"
              type="text"
              maxlength="50"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item class="w-full" label="所在汽配城" prop="address">
            <el-select
              v-model="ruleForm.autoPartsCity"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in autoPartsCitys"
                :key="item.id"
                :label="item.name"
                :value="item.id.toString()"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="w-full"
            label="统一社会信用代码"
            prop="bizLicenceCode"
          >
            <el-input
              v-model="ruleForm.bizLicenceCode"
              type="text"
              maxlength="18"
              placeholder="请输入统一社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item class="w-full" label="营业执照" prop="licencesImage">
            <div class="upload_file">
              <UploadFileImg
                :maxSize="1"
                listType="picture-card"
                :limit="1"
                :fileList="imgList"
                :isMultiple="false"
                :showFileList="true"
                :hideFlag="true"
                @getFileList="(fileList) => getFileList(fileList)"
              />
            </div>
          </el-form-item>
          <el-form-item class="w-full" label="公司简介" prop="description">
            <el-input
              v-model="ruleForm.description"
              type="text"
              maxlength="200"
              placeholder="请输入公司简介"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="flex flex-center margin-top4 margin-bottom4">
        <div class="flex1"></div>
        <div @click="nextTime" class="background-color10 textColor03 fontSize14 margin-left6 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4 border">
          先跳过,下次再认证
        </div>
        <div @click="changeStatus(STATUS.INVITECODE)" class="background-color10 textColor03 fontSize14 margin-left6 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4 border">
          我要加入已认证的公司!
        </div>
        <div @click="submitForm()" class="bg-warning textColor10 fontSize14 margin-left6 padding-left3 padding-right3 padding-top1 padding-bottom1 actives05 cursor-pointer border-radius4">
          确认认证
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {STATUS} from "../../../store/index";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import {mobileValidate, bzCodeValidate} from "@/utils/validate";
import {getDict} from "@/api/basic";
import {addcompany} from "@/api/company";

export default {
  components: {
    SelectArea,
    UploadFileImg,
  },
  data() {
    return {
      STATUS,
      imgList: [],
      autoPartsCitys: [],
      ruleForm: {
        name: "",
        type: "dealer",
        contacts: "",
        phone: "",
        areaId: "",
        address: "",
        bizLicenceCode: "",
        licencesImage: "",
        description: "",
        autoPartsCity: "",
      },
      rules: {
        phone: [{validator: mobileValidate, trigger: "blur"}],
        bizLicenceCode: [{validator: bzCodeValidate, trigger: "blur"}],
        type: [
          {required: true, message: "请选择公司类型", trigger: "change"},
        ],
        name: [
          {required: true, message: "请输入公司名称", trigger: "change"},
        ],
        contacts: [
          {required: true, message: "联系人姓名不能为空", trigger: "change"},
        ],
        areaId: [
          {required: true, message: "请选择公司所在地址", trigger: "change"},
        ],
        address: [
          {required: true, message: "请输入公司详细地址", trigger: "change"},
        ],
      },
    };
  },
  computed: {
    ...mapState({
      regParams: (state) => state.regParams,
      cacheComInfo: (state) => state.cacheComInfo,
    }),
  },
  watch: {
    imgList: {
      handler: function (newVal) {
        this.ruleForm.licencesImage =
          newVal && newVal.length > 0 ? newVal[0].origin : "";
      },
      deep: true,
    },
    regParams: {
      handler: function (newVal) {
        this.ruleForm = {...newVal};
      },
      deep: true,
    },
    cacheComInfo: {
      handler: function (newVal) {
        this.ruleForm = {...newVal};
      },
      deep: true,
    },
  },
  mounted() {
    this.getDict();
    if (this.regParams) {
      const {provinceId, cityId, areaId, name, phone} = this.regParams;
      this.ruleForm = {
        ...this.ruleForm,
        contacts: name,
        phone: phone,
        areaId,
        areaIds: [provinceId, cityId, areaId],
      };
    }
    if (this.cacheComInfo) {
      this.ruleForm = {
        ...this.ruleForm,
        ...this.cacheComInfo,
      };
    }
  },
  methods: {
    getDict() {
      getDict({
        type: "autoPartsCity",
      }).then((res) => {
        this.autoPartsCitys = res;
      });
    },
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.areaId = dataObj.areaIds[2];
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((valid, err) => {
        if (valid) {
          addcompany({
            ...this.ruleForm,
            areaId: Number(this.ruleForm.areaId),
          }).then(() => {
            this.changeStatus(STATUS.COMINFO2);
          });
        } else {
          let msg = "";
          for (const key in err) {
            if (Object.hasOwnProperty.call(err, key)) {
              const element = err[key];
              msg = element[0].message;
            }
          }
          this.$message.warning(msg);
          return false;
        }
      });
    },
    nextTime() {
      this.$store.dispatch("getProfile");
      this.$router.replace("/home/index");
      // window.location.href = "./";
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    getFileList(fileList) {
      this.imgList = fileList;
    },
  },
};
</script>
<style scoped>
.step-num {
  width: 20px;
  height: 20px;
}
</style>