<template>
  <el-cascader
    style="width:90px;max-width: 180px;"
    class=" bg-gray-900 border-none"
    v-model="areaObjId"
    :placeholder="placeholder"
    :options="addressOptions"
    :show-all-levels="false"
    @change="handleChange"
    ><template #prefix> <i class="iconfont icon-login_verify"></i> </template
  ></el-cascader>
</template>

<script>
import { getAddressList } from "@/utils/splicingDataLevel2";
export default {
  name: "selectArea",
  data() {
    return {
      addressOptions: [],
      areaObjId: [],
    };
  },
  props: {
    placeholder: {
      type: [String],
      default: "请选择所在区域",
    },
    areaIds: {
      type: [Array],
      default: () => {
        return [];
      },
    },
  },
  watch: {
    areaIds(val) {
      // console.log("-----------", val);
      this.areaObjId = val;
    },
  },
  created() {
    //获取地址列表
    this.addressOptions = getAddressList();
  },
  mounted() {
    this.areaObjId = this.areaIds;
  },
  methods: {
    handleChange(val) {
      this.areaObjId = val;
      // this.areaName = name;
      let locationStr = JSON.stringify({
        province: val[0],
        city: val[1],
      });
      localStorage.setItem("location", locationStr);
      window.location.reload()
    },
  },
};
</script>

<style lang="less" scoped></style>
