<template>
  <div class="m-auto box-border">
    <div class="text-xl font-bold mb-s-xxs">注册</div>
    <div class="text-base mb-s-xxs">你好！有备平台欢迎您，请先注册账号</div>
    <div class="text-sm text-warning margin-bottom3">
      * 下列信息均为必填项，请耐心填写
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item prop="name" class="w-full">
        <el-input
          v-model="ruleForm.name"
          maxlength="25"
          class="noborder"
          type="text"
          clearable
          placeholder="* 请输入姓名,如:***公司-张三"
          ><template #prefix>
            <i class="iconfont icon-login_user"></i> </template
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm.phone"
          maxlength="11"
          class="noborder"
          type="text"
          clearable
          placeholder="* 请输入手机号"
          ><template #prefix> <i class="iconfont icon-login_tel"></i> </template
        ></el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm.smsCaptcha"
          type="text"
          class="noborder"
          maxlength="6"
          clearable
          placeholder="* 请输入验证码"
          ><template #append>
            <msgCode :mobile="ruleForm.phone" :type="'register'" />
          </template>
          <template #prefix>
            <div
              class="flex justify-center items-center m-auto"
              style="line-height: 40px;"
            >
              <i class="iconfont icon-login_verify"></i>
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" class="w-full">
        <el-row>
          <el-col :span="20">
            <el-input
              v-model="ruleForm.password"
              class="noborder"
              :type="showPsd ? 'text' : 'password'"
              clearable
              placeholder="* 请输入密码"
            >
              <template #prefix>
                <i class="iconfont icon-login_password"></i>
              </template>
            </el-input>
          </el-col>
          <el-col :span="4" style="text-align:center;">
            <i class="el-icon-view" @click="showPsd = !showPsd"></i>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="w-full noborder" prop="areaId">
        <SelectArea
          style="width: 100%"
          :placeholder="'* 请选择所在区域'"
          @change="areaChange"
        />
      </el-form-item>
    </el-form>
    <div class="flex justify-start w-full text-sm mb-s-s">
      <span
        class="cursor-pointer text-gray-900"
        @click="changeStatus(STATUS.LOGIN)"
        >已有账号,去登录</span
      >
    </div>
    <button
      :disabled="!isAgree"
      class="w-full rounded-full py-4 text-center text-gray-100  mb-s-s"
      :class="{
        'bg-warning': isAgree,
        'bg-gray-900': !isAgree,
      }"
      @click="submit"
    >
      注册
    </button>
    <div class="text-left w-full text-sm text-gray-900">
      <i
        v-if="isAgree"
        class="iconfont icon-radio_sel cursor-pointer text-main"
        @click="isAgree = !isAgree"
      ></i>
      <i
        v-else
        class="iconfont icon-radio_nor cursor-pointer"
        @click="isAgree = !isAgree"
      ></i>
      我已阅读并同意
      <span class="text-black-300"
        ><span class="text-warning cursor-pointer" @click="showMsg(1)"
          >《服务协议》</span
        >和<span class="text-warning cursor-pointer" @click="showMsg(2)"
          >《隐私政策》</span
        >
      </span>
    </div>
    <el-dialog title="服务协议" :visible.sync="showProto" width="65%">
      <Proto />
    </el-dialog>
    <el-dialog title="隐私政策" :visible.sync="showPravicy" width="65%">
      <Pravicy />
    </el-dialog>
  </div>
</template>

<script>
import { STATUS } from "../../../store/index";
import { mobileValidate, passwordValidate } from "@/utils/validate";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import msgCode from "@/components/msgCode/msgCode";
import Proto from "./Proto.vue";
import Pravicy from "./Pravicy.vue";
export default {
  components: {
    SelectArea,
    msgCode,
    Proto,
    Pravicy,
  },
  data() {
    return {
      STATUS,
      isAgree: false,
      showProto: false,
      showPravicy: false,
      showPsd: false,
      ruleForm: {
        name: "",
        phone: "",
        password: "",
        smsCaptcha: "",
      },
      rules: {
        phone: [{ validator: mobileValidate, trigger: "blur" }],
        password: [{ validator: passwordValidate, trigger: "blur" }],
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      },
    };
  },
  methods: {
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.areaId = dataObj.areaIds[2] || dataObj.areaIds[1];
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    showMsg(type) {
      if (type === 1) {
        this.showProto = true;
        return;
      }
      this.showPravicy = true;
    },
    submit() {
      if (!this.ruleForm.areaId) {
        this.$message.warning("请选择区域");
        return;
      }
      this.$refs.ruleFormRef.validate((valid, err) => {
        if (valid) {
          this.$store.dispatch("reg", {
            ...this.ruleForm,
            terminal: "web",
            confirm: false,
          });
        } else {
          let msg = "";
          for (const key in err) {
            if (Object.hasOwnProperty.call(err, key)) {
              const element = err[key];
              msg = element[0].message;
            }
          }
          this.$message.warning(msg);
          return false;
        }
      });
    },
  },
};
</script>
